<template>
  <div class="translate-difficulty">
    <a-form-model :model="value">
      <div v-for="(formItem, formIndex) in formList" :key="formItem.rowKey || formIndex" class="difficulty-type">
        <a-form-model-item class="type-title">
          <a-row v-bind="flex">
            <a-col v-bind="col[0]" class="form-title-item type-title-preview">
              <a-input
                v-if="isEdit"
                :prefix="`${numbers[formIndex] || formIndex + 1}、`"
                placeholder="内容"
                v-model="formItem.title"
              />
              <div v-else v-text="`${numbers[formIndex] || formIndex + 1}、${formItem.title || ''}`" />
            </a-col>
            <a-col v-bind="col[1]">
              <a-input v-if="isEdit" addonBefore="权重" suffix="%" v-model="formItem.weight" />
              <div v-else v-text="`权重：${formItem.weight || ''}%`" />
            </a-col>
            <a-col v-bind="col[2]">
              <a-row v-bind="flex">
                <a-col><a-button shape="circle" icon="minus" @click="delItem(formIndex)"></a-button></a-col>
                <a-col
                  ><a-button
                    :disabled="formList.length > 9"
                    shape="circle"
                    icon="plus"
                    @click="addItem(formIndex)"
                  ></a-button
                ></a-col>
              </a-row>
            </a-col>
          </a-row>
        </a-form-model-item>
        <div
          v-for="(formChild, formChildIndex) in formItem.categories"
          :key="formChild.rowKey || formChildIndex"
          class="category-title"
        >
          <a-form-model-item>
            <a-row v-bind="flex">
              <a-col v-bind="calcTotalCol" class="category-title-preview">
                <a-input v-if="isEdit" :prefix="`${formIndex + 1}.${formChildIndex + 1}`" v-model="formChild.title" />
                <div v-else v-text="`${formIndex + 1}.${formChildIndex + 1} ${formChild.title || ''}`" />
              </a-col>
              <a-col v-bind="col[2]">
                <a-row v-bind="flex">
                  <a-col
                    ><a-button shape="circle" icon="minus" @click="delItem(formIndex, formChildIndex)"></a-button
                  ></a-col>
                  <a-col
                    ><a-button shape="circle" icon="plus" @click="addItem(formIndex, formChildIndex)"></a-button
                  ></a-col>
                </a-row>
              </a-col>
            </a-row>
          </a-form-model-item>
          <a-form-model-item v-for="(option, optionIndex) in formChild.options" :key="option.rowKey || optionIndex">
            <a-row v-bind="flex">
              <a-col v-bind="col[0]">
                <a-input
                  v-if="isEdit"
                  placeholder="选项"
                  :addonBefore="optionIndex < 26 ? String.fromCharCode(65 + optionIndex) : optionIndex"
                  v-model="option.title"
                />
                <div
                  v-else
                  v-text="
                    `${optionIndex < 26 ? String.fromCharCode(65 + optionIndex) : optionIndex} ${option.title || ''}`
                  "
                />
              </a-col>
              <a-col v-bind="col[1]">
                <a-input-number v-if="isEdit" class="input-factor" placeholder="系数" v-model="option.factor" />
                <div v-else v-text="option.factor || ''" />
              </a-col>
              <a-col v-bind="col[2]">
                <a-row v-bind="flex">
                  <a-col
                    ><a-button
                      shape="circle"
                      icon="minus"
                      @click="delItem(formIndex, formChildIndex, optionIndex)"
                    ></a-button
                  ></a-col>
                  <a-col
                    ><a-button
                      :disabled="formChild.options.length > 25"
                      shape="circle"
                      icon="plus"
                      @click="addItem(formIndex, formChildIndex, optionIndex)"
                    ></a-button
                  ></a-col>
                </a-row>
              </a-col>
            </a-row>
          </a-form-model-item>
        </div>
      </div>
    </a-form-model>
  </div>
</template>

<script>
export default {
  data() {
    return {
      numbers: ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十'],
      flex: {
        type: 'flex',
        justify: 'space-between',
      },
      formList: [
        {
          rowKey: 54641,
          title: '旁白数量（前10话平均每话个数）',
          weight: '50',
          categories: [
            {
              rowKey: 84532,
              title: '旁白平均每话数量',
              options: [
                { rowKey: 12345, title: '20以上', factor: '0.4' },
                { rowKey: 54821, title: '13-19个', factor: '0.2' },
                { rowKey: 94421, title: '6-12个', factor: '0.1' },
                { rowKey: 56154, title: '0-5个', factor: '0' },
              ],
            },
          ],
        },
        {
          rowKey: 54641,
          title: '旁白数量（前10话平均每话个数）',
          weight: '50',
          categories: [
            {
              rowKey: 84532,
              title: '旁白平均每话数量',
              options: [
                { rowKey: 12345, title: '20以上', factor: '0.4' },
                { rowKey: 54821, title: '13-19个', factor: '0.2' },
              ],
            },
            {
              rowKey: 84532,
              title: '旁白平均每话数量',
              options: [
                { rowKey: 12345, title: '20以上', factor: '0.4' },
                { rowKey: 54821, title: '13-19个', factor: '0.2' },
              ],
            },
          ],
        },
      ],
    }
  },
  computed: {
    calcTotalCol() {
      return this.col.slice(0, 2).reduce((total, item) => ({ ...item, span: item.span + total.span }), { span: 1 })
    },
    col() {
      return this.isEdit ? [{ span: 14 }, { span: 5 }, { span: 3 }] : [{ span: 20 }, { span: 4 }, { span: 0 }]
    },
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    isEdit: {
      type: Boolean,
      default: () => false,
    },
  },
  watch: {
    formList() {
      if (!this.formList.length) {
        this.formList = [this.genType()]
      }
    },
  },
  mounted() {
    if (!this.formList.length) {
      this.formList = [this.genType()]
    }
  },
  methods: {
    genType(categoryCount = 1, optionCount) {
      return {
        rowKey: Math.random(),
        title: undefined,
        weight: undefined,
        categories: Array.from(Array(categoryCount), () => this.genCategory(optionCount)),
      }
    },
    genCategory(optionCount = 4) {
      return {
        rowKey: Math.random(),
        title: undefined,
        options: Array.from(Array(optionCount), () => this.genOption()),
      }
    },
    genOption() {
      return { rowKey: Math.random(), title: undefined, factor: undefined }
    },
    addItem(typeIndex, categoryIndex, optionIndex) {
      if (categoryIndex == undefined) {
        this.formList.splice(typeIndex + 1, 0, this.genType())
      } else if (optionIndex == undefined) {
        this.formList[typeIndex].categories.splice(categoryIndex + 1, 0, this.genCategory())
      } else {
        this.formList[typeIndex].categories[categoryIndex].options.splice(optionIndex + 1, 0, this.genOption())
      }
    },
    delItem(typeIndex, categoryIndex, optionIndex) {
      if (categoryIndex == undefined) {
        if (this.formList.length == 1) {
          this.formList.splice(
            typeIndex,
            1,
            this.genType(
              this.formList[typeIndex]?.categories.length,
              this.formList[typeIndex]?.categories[0]?.options.length
            )
          )
        } else {
          this.formList.splice(typeIndex, 1)
        }
      } else if (optionIndex == undefined) {
        if (this.formList[typeIndex].categories.length == 1) {
          this.formList[typeIndex].categories.splice(
            categoryIndex,
            1,
            this.genCategory(this.formList[typeIndex]?.categories[categoryIndex]?.options.length)
          )
        } else {
          this.formList[typeIndex].categories.splice(categoryIndex, 1)
        }
      } else {
        if (this.formList[typeIndex].categories[categoryIndex].options.length == 1) {
          this.formList[typeIndex].categories[categoryIndex].options.splice(optionIndex, 1, this.genOption())
        } else {
          this.formList[typeIndex].categories[categoryIndex].options.splice(optionIndex, 1)
        }
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
.ant-form-item {
  margin-bottom: 0;
}

.form-title-item {
  display: flex;
  align-items: center;
  padding-top: 1px;
}

/deep/ .ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 40px !important;
}

.translate-difficulty {
  padding-right: 8px;

  .difficulty-type:not(:first-of-type) {
    // border-top: 1px solid #eaecee;
  }

  .difficulty-type {
    padding-top: 10px;

    .type-title {
      // margin-bottom: 10px;
      .type-title-preview {
        font-weight: bold;
        font-size: 16px;

        /deep/ .ant-input-affix-wrapper {
          font-size: 16px;
          font-weight: bold;

          .ant-input {
            font-size: 16px;
            font-weight: bold;
          }
        }
      }
    }

    .type-title:not(:first-of-type) {
      margin: 10px 0;
      // border-top: 1px solrowKey #eaecee;
    }

    .category-title {
      // border-top: 1px solrowKey #eaecee;
      // padding: 10px 0;
      .category-title-preview {
        font-weight: bold;

        /deep/ .ant-input-affix-wrapper {
          .ant-input {
            font-weight: bold;
          }
        }
      }
    }
  }
}

/deep/ .input-factor {
  input {
    // text-align: center;
  }
}
</style>