var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "translate-difficulty" },
    [
      _c(
        "a-form-model",
        { attrs: { model: _vm.value } },
        _vm._l(_vm.formList, function (formItem, formIndex) {
          return _c(
            "div",
            {
              key: formItem.rowKey || formIndex,
              staticClass: "difficulty-type",
            },
            [
              _c(
                "a-form-model-item",
                { staticClass: "type-title" },
                [
                  _c(
                    "a-row",
                    _vm._b({}, "a-row", _vm.flex, false),
                    [
                      _c(
                        "a-col",
                        _vm._b(
                          { staticClass: "form-title-item type-title-preview" },
                          "a-col",
                          _vm.col[0],
                          false
                        ),
                        [
                          _vm.isEdit
                            ? _c("a-input", {
                                attrs: {
                                  prefix: `${
                                    _vm.numbers[formIndex] || formIndex + 1
                                  }、`,
                                  placeholder: "内容",
                                },
                                model: {
                                  value: formItem.title,
                                  callback: function ($$v) {
                                    _vm.$set(formItem, "title", $$v)
                                  },
                                  expression: "formItem.title",
                                },
                              })
                            : _c("div", {
                                domProps: {
                                  textContent: _vm._s(
                                    `${
                                      _vm.numbers[formIndex] || formIndex + 1
                                    }、${formItem.title || ""}`
                                  ),
                                },
                              }),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        _vm._b({}, "a-col", _vm.col[1], false),
                        [
                          _vm.isEdit
                            ? _c("a-input", {
                                attrs: { addonBefore: "权重", suffix: "%" },
                                model: {
                                  value: formItem.weight,
                                  callback: function ($$v) {
                                    _vm.$set(formItem, "weight", $$v)
                                  },
                                  expression: "formItem.weight",
                                },
                              })
                            : _c("div", {
                                domProps: {
                                  textContent: _vm._s(
                                    `权重：${formItem.weight || ""}%`
                                  ),
                                },
                              }),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        _vm._b({}, "a-col", _vm.col[2], false),
                        [
                          _c(
                            "a-row",
                            _vm._b({}, "a-row", _vm.flex, false),
                            [
                              _c(
                                "a-col",
                                [
                                  _c("a-button", {
                                    attrs: { shape: "circle", icon: "minus" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.delItem(formIndex)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                [
                                  _c("a-button", {
                                    attrs: {
                                      disabled: _vm.formList.length > 9,
                                      shape: "circle",
                                      icon: "plus",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addItem(formIndex)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._l(formItem.categories, function (formChild, formChildIndex) {
                return _c(
                  "div",
                  {
                    key: formChild.rowKey || formChildIndex,
                    staticClass: "category-title",
                  },
                  [
                    _c(
                      "a-form-model-item",
                      [
                        _c(
                          "a-row",
                          _vm._b({}, "a-row", _vm.flex, false),
                          [
                            _c(
                              "a-col",
                              _vm._b(
                                { staticClass: "category-title-preview" },
                                "a-col",
                                _vm.calcTotalCol,
                                false
                              ),
                              [
                                _vm.isEdit
                                  ? _c("a-input", {
                                      attrs: {
                                        prefix: `${formIndex + 1}.${
                                          formChildIndex + 1
                                        }`,
                                      },
                                      model: {
                                        value: formChild.title,
                                        callback: function ($$v) {
                                          _vm.$set(formChild, "title", $$v)
                                        },
                                        expression: "formChild.title",
                                      },
                                    })
                                  : _c("div", {
                                      domProps: {
                                        textContent: _vm._s(
                                          `${formIndex + 1}.${
                                            formChildIndex + 1
                                          } ${formChild.title || ""}`
                                        ),
                                      },
                                    }),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              _vm._b({}, "a-col", _vm.col[2], false),
                              [
                                _c(
                                  "a-row",
                                  _vm._b({}, "a-row", _vm.flex, false),
                                  [
                                    _c(
                                      "a-col",
                                      [
                                        _c("a-button", {
                                          attrs: {
                                            shape: "circle",
                                            icon: "minus",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.delItem(
                                                formIndex,
                                                formChildIndex
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-col",
                                      [
                                        _c("a-button", {
                                          attrs: {
                                            shape: "circle",
                                            icon: "plus",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.addItem(
                                                formIndex,
                                                formChildIndex
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._l(formChild.options, function (option, optionIndex) {
                      return _c(
                        "a-form-model-item",
                        { key: option.rowKey || optionIndex },
                        [
                          _c(
                            "a-row",
                            _vm._b({}, "a-row", _vm.flex, false),
                            [
                              _c(
                                "a-col",
                                _vm._b({}, "a-col", _vm.col[0], false),
                                [
                                  _vm.isEdit
                                    ? _c("a-input", {
                                        attrs: {
                                          placeholder: "选项",
                                          addonBefore:
                                            optionIndex < 26
                                              ? String.fromCharCode(
                                                  65 + optionIndex
                                                )
                                              : optionIndex,
                                        },
                                        model: {
                                          value: option.title,
                                          callback: function ($$v) {
                                            _vm.$set(option, "title", $$v)
                                          },
                                          expression: "option.title",
                                        },
                                      })
                                    : _c("div", {
                                        domProps: {
                                          textContent: _vm._s(
                                            `${
                                              optionIndex < 26
                                                ? String.fromCharCode(
                                                    65 + optionIndex
                                                  )
                                                : optionIndex
                                            } ${option.title || ""}`
                                          ),
                                        },
                                      }),
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                _vm._b({}, "a-col", _vm.col[1], false),
                                [
                                  _vm.isEdit
                                    ? _c("a-input-number", {
                                        staticClass: "input-factor",
                                        attrs: { placeholder: "系数" },
                                        model: {
                                          value: option.factor,
                                          callback: function ($$v) {
                                            _vm.$set(option, "factor", $$v)
                                          },
                                          expression: "option.factor",
                                        },
                                      })
                                    : _c("div", {
                                        domProps: {
                                          textContent: _vm._s(
                                            option.factor || ""
                                          ),
                                        },
                                      }),
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                _vm._b({}, "a-col", _vm.col[2], false),
                                [
                                  _c(
                                    "a-row",
                                    _vm._b({}, "a-row", _vm.flex, false),
                                    [
                                      _c(
                                        "a-col",
                                        [
                                          _c("a-button", {
                                            attrs: {
                                              shape: "circle",
                                              icon: "minus",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.delItem(
                                                  formIndex,
                                                  formChildIndex,
                                                  optionIndex
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-col",
                                        [
                                          _c("a-button", {
                                            attrs: {
                                              disabled:
                                                formChild.options.length > 25,
                                              shape: "circle",
                                              icon: "plus",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.addItem(
                                                  formIndex,
                                                  formChildIndex,
                                                  optionIndex
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                )
              }),
            ],
            2
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }